import { Divider } from '@material-ui/core';
import Blockquote from './Blockquote';
import Code from './Code';
import Heading from './Heading';
import InlineCode from './InlineCode';
import List from './List';
import ListItem from './ListItem';
import Paragraph from './Paragraph';
import React from 'react';

const components = {
  blockquote: Blockquote,
  code: Code,
  h1: props => <Heading variant="h1" {...props} />,
  h2: props => <Heading variant="h2" {...props} />,
  h3: props => <Heading variant="h3" {...props} />,
  h4: props => <Heading variant="h4" {...props} />,
  h5: props => <Heading variant="h5" {...props} />,
  h6: props => <Heading variant="h6" {...props} />,
  hr: Divider,
  inlineCode: InlineCode,
  li: ListItem,
  ol: props => <List variant="ol" {...props} />,
  p: Paragraph,
  ul: props => <List variant="ul" {...props} />
};

export default components;
