import mock from 'src/utils/mock';
import moment from 'moment';

mock.onGet('/api/projects/account/statistics').reply(200, {
  statistics: {
    payout: '4,250',
    projects: '12,500',
    visitors: '230',
    watching: '5'
  }
});

mock.onGet('/api/projects/account/projects').reply(200, {
  projects: [
    {
      author: {
        avatar: '/static/images/avatars/avatar_6.png',
        id: '5e86809283e28b96d2d38537',
        name: 'Katarina Smith'
      },
      id: '5e8dcf105a6732b3ed82cf7a',
      isLiked: true,
      likes: 57,
      location: 'Europe',
      media: '/static/images/projects/project_3.png',
      rating: 3,
      subscribers: 5,
      technology: 'Angular JS',
      title: 'Ten80 Web Design',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(24, 'minutes')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/projects/account/todos').reply(200, {
  todos: [
    {
      deadline: moment()
        .subtract(2, 'days')
        .toDate()
        .getTime(),
      done: false,
      id: '5e8dd0b59fb490099917eb29',
      title: 'Finish the proposal design and contract for the client ASAP'
    },
    {
      deadline: moment()
        .toDate()
        .getTime(),
      done: false,
      id: '5e8dd0ba89c3f86059b41383',
      title: 'Finish the proposal design and contract for the client ASAP'
    },
    {
      deadline: moment()
        .add(2, 'days')
        .toDate()
        .getTime(),
      done: false,
      id: '5e8dd0c028284550358972c7',
      title:
        'Upload the files for the client @Adrian Stefan with the fonts, assets exported and all the fancy svgs'
    },
    {
      deadline: moment()
        .add(5, 'days')
        .toDate()
        .getTime(),
      done: false,
      id: '5e8dd0c549ceb790f8c41e96',
      title: 'Re-write the card component with hooks and context'
    },
    {
      deadline: moment()
        .add(5, 'days')
        .toDate()
        .getTime(),
      done: false,
      id: '5e8dd0cb2fb6bf63b2e7d298',
      title: 'Send proposal for the fintech project'
    },
    {
      deadline: moment()
        .add(7, 'days')
        .toDate()
        .getTime(),
      done: true,
      id: '5e8dd0d2ad70962ca35da534',
      title:
        'Create todo app with react hooks just to get a better understanding of the concept'
    }
  ]
});

mock.onGet('/api/projects/projects').reply(200, {
  projects: [
    {
      author: {
        avatar: '/static/images/avatars/avatar_5.png',
        id: '5e887b7602bdbc4dbb234b27',
        name: 'Anje Keizer'
      },
      description: `
We're looking for experienced Developers and Product Designers to
come aboard and help us build succesful businesses through software.
      `,
      id: '5e8dcef8f95685ce21f16f3d',
      isLiked: true,
      likes: 7,
      location: 'Europe',
      media: '/static/images/projects/project_1.png',
      rating: 5,
      subscribers: 2,
      technology: 'Vue JS',
      title: 'Mella Full Screen Slider',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(24, 'minutes')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_9.png',
        id: '5e887d0b3d090c1b8f162003',
        name: 'Emilee Simchenko'
      },
      description: `
We're looking for experienced Developers and Product Designers to
come aboard and help us build succesful businesses through software.
      `,
      id: '5e8dcf076c50b9d8e756a5a2',
      isLiked: false,
      likes: 12,
      location: 'Europe',
      media: '/static/images/projects/project_2.png',
      rating: 4.5,
      subscribers: 3,
      technology: 'Angular',
      title: 'Dashboard Design',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(1, 'hour')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_10.png',
        id: '5e88792be2d4cfb4bf0971d9',
        name: 'Elliott Stone'
      },
      description: `
We're looking for experienced Developers and Product Designers to
come aboard and help us build succesful businesses through software.
      `,
      id: '5e8dcf105a6732b3ed82cf7a',
      isLiked: true,
      likes: 18,
      location: 'Europe',
      media: '/static/images/projects/project_3.png',
      rating: 4.7,
      subscribers: 8,
      technology: 'Ember JS',
      title: 'Ten80 Web Design',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(16, 'hour')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_11.png',
        id: '5e8877da9a65442b11551975',
        name: 'Shen Zhi'
      },
      description: `
We're looking for experienced Developers and Product Designers to
come aboard and help us build succesful businesses through software.
      `,
      id: '5e8dcf1cc7155d0e947dc27f',
      isLiked: false,
      likes: 1,
      location: 'Europe',
      media: '/static/images/projects/project_4.png',
      rating: 2,
      subscribers: 10,
      technology: 'Java Spring',
      title: 'Neura e-commerce UI Kit',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(3, 'days')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_3.png',
        id: '5e887ac47eed253091be10cb',
        name: 'Cao Yu'
      },
      description: `
We're looking for experienced Developers and Product Designers to
come aboard and help us build succesful businesses through software.
      `,
      id: '5e8dcf252313876001e83221',
      isLiked: false,
      likes: 7,
      location: 'Europe',
      media: '/static/images/projects/project_5.jpg',
      rating: 5,
      subscribers: 2,
      technology: 'Django',
      title: 'Administrator Dashboard',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(7, 'days')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_5.png',
        id: '5e887b7602bdbc4dbb234b27',
        name: 'Anje Keizer'
      },
      description: `
We're looking for experienced Developers and Product Designers to
come aboard and help us build succesful businesses through software.
      `,
      id: '5e8dcf4250d77c954b04902e',
      isLiked: true,
      likes: 4,
      location: 'Europe',
      media: '',
      rating: 4.2,
      subscribers: 12,
      technology: 'React JS',
      title: 'Kalli UI Kit',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(8, 'days')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/projects/projects/1').reply(200, {
  project: {
    active: true,
    activities: [
      {
        createdAt: moment()
          .subtract(23, 'minutes')
          .toDate()
          .getTime(),
        description: 'has uploaded a new file',
        id: '5e8dd0828d628e6f40abdfe8',
        subject: 'Project owner',
        type: 'upload_file'
      },
      {
        createdAt: moment()
          .subtract(2, 'hours')
          .toDate()
          .getTime(),
        description: 'joined team as a Front-End Developer',
        id: '5e8dd0893a6725f2bb603617',
        subject: 'Adrian Stefan',
        type: 'join_team'
      },
      {
        createdAt: moment()
          .subtract(9, 'hours')
          .toDate()
          .getTime(),
        description: 'joined team as a Full Stack Developer',
        id: '5e8dd08f44603e3300b75cf1',
        subject: 'Alexndru Robert',
        type: 'join_team'
      },
      {
        createdAt: moment()
          .subtract(2, 'days')
          .toDate()
          .getTime(),
        description: 'raised the project budget',
        id: '5e8dd0960f3f0fe04e64d8f4',
        subject: 'Project owner',
        type: 'price_change'
      },
      {
        createdAt: moment()
          .subtract(4, 'days')
          .toDate()
          .getTime(),
        description: 'created',
        id: '5e8dd09db94421c502c53d13',
        subject: 'Contest',
        type: 'contest_created'
      }
    ],
    author: {
      avatar: '/static/images/avatars/avatar_9.png',
      bio: 'We build beautiful functional themes for web professionals',
      id: '5e887d0b3d090c1b8f162003',
      name: 'Emilee Simchenko'
    },
    brief: `
Design files are attached in the files tab.

Develop the web app screens for our product called "PDFace". Please look at the wireframes, system activity workflow and read the section above to understand what we're trying to archive.

There's not many screens we need designed, but there will be modals and various other system triggered events that will need to be considered.

The project has been created in Sketch so let me know if there are any problems opening this project and I'll try to convert into a usable file.
    `,
    currency: 'USD',
    deadline: moment()
      .add(7, 'days')
      .toDate()
      .getTime(),
    endDate: moment()
      .add(14, 'days')
      .toDate()
      .getTime(),
    files: [
      {
        id: '5e8dd0721b9e0fab56d7238b',
        mimeType: 'image/png',
        name: 'example-project1.jpg',
        size: 1024 * 1024 * 3,
        url: '/static/images/projects/project_4.png'
      },
      {
        id: '5e8dd0784431995a30eb2586',
        mimeType: 'application/zip',
        name: 'docs.zip',
        size: 1024 * 1024 * 25,
        url: '#'
      },
      {
        id: '5e8dd07cbb62749296ecee1c',
        mimeType: 'image/png',
        name: 'example-project2.jpg',
        size: 1024 * 1024 * 2,
        url: '/static/images/projects/project_1.png'
      }
    ],
    members: [
      {
        avatar: '/static/images/avatars/avatar_2.png',
        bio: 'Front End Developer',
        id: '5e887a62195cc5aef7e8ca5d',
        name: 'Ekaterina Tankova'
      },
      {
        avatar: '/static/images/avatars/avatar_3.png',
        bio: 'UX Designer',
        id: '5e887ac47eed253091be10cb',
        name: 'Cao Yu'
      },
      {
        avatar: '/static/images/avatars/avatar_5.png',
        bio: 'Copyright',
        id: '5e887b7602bdbc4dbb234b27',
        name: 'Anje Keizer'
      }
    ],
    price: '12,500',
    subscribers: [
      {
        avatar: '/static/images/avatars/avatar_2.png',
        commonContacts: 12,
        cover: '/static/images/covers/cover_2.jpg',
        id: '5e887a62195cc5aef7e8ca5d',
        labels: [
          'User Experience',
          'FrontEnd development',
          'HTML5',
          'VueJS',
          'ReactJS'
        ],
        name: 'Ekaterina Tankova'
      },
      {
        avatar: '/static/images/avatars/avatar_3.png',
        commonContacts: 5,
        cover: '/static/images/covers/cover_3.jpg',
        id: '5e887ac47eed253091be10cb',
        labels: [
          'User Interface',
          'FullStack development',
          'Angular',
          'ExpressJS'
        ],
        name: 'Cao Yu'
      },
      {
        avatar: '/static/images/avatars/avatar_6.png',
        commonContacts: 17,
        cover: '/static/images/covers/cover_1.jpg',
        id: '5e86809283e28b96d2d38537',
        labels: ['BackEnd development', 'Firebase', 'MongoDB', 'ExpressJS'],
        name: 'Katarina Smith'
      }
    ],
    tags: ['React JS'],
    title: 'Develop a PDF Export App',
    updatedAt: moment()
      .subtract(23, 'minutes')
      .toDate()
      .getTime()
  }
});
