import { SvgIcon } from '@material-ui/core';
import React from 'react';

const ProjectsIcon = (props) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <mask
        id="mask0_13833_21307"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_13833_21307)">
        <path
          d="M4.66659 13.3334V5.98338C4.66659 5.61672 4.79993 5.30561 5.06659 5.05005C5.33326 4.79449 5.64993 4.66672 6.01659 4.66672H13.3333C13.6999 4.66672 14.0138 4.79727 14.2749 5.05838C14.536 5.31949 14.6666 5.63338 14.6666 6.00005V11.3334L11.3333 14.6667H5.99993C5.63326 14.6667 5.31937 14.5362 5.05826 14.2751C4.79715 14.0139 4.66659 13.7001 4.66659 13.3334ZM1.34993 4.16672C1.28326 3.80005 1.35548 3.46949 1.56659 3.17505C1.77771 2.88061 2.06659 2.70005 2.43326 2.63338L9.6666 1.35005C10.0333 1.28338 10.3638 1.35561 10.6583 1.56672C10.9527 1.77783 11.1333 2.06672 11.1999 2.43338L11.3666 3.33338H5.99993C5.26659 3.33338 4.63882 3.59449 4.11659 4.11672C3.59437 4.63894 3.33326 5.26672 3.33326 6.00005V12.3667C3.15548 12.2667 3.00271 12.1334 2.87493 11.9667C2.74715 11.8001 2.66659 11.6112 2.63326 11.4001L1.34993 4.16672ZM13.3333 10.6667H10.6666V13.3334L13.3333 10.6667Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};

export default ProjectsIcon;
