import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4)
  }
}));

function List({ variant: Component, children, ...rest }) {
  const classes = useStyles();

  return (
    <Component className={classes.root} {...rest}>
      {children}
    </Component>
  );
}

List.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf(['ul', 'ol'])
};

export default List;
