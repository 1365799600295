import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '& > a': {
      color: theme.palette.secondary.main
    },
    marginBottom: theme.spacing(2)
  }
}));

function Paragraph(props) {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      color="textPrimary"
      className={classes.root}
      {...props}
    />
  );
}

export default Paragraph;
