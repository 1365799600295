import { SvgIcon } from '@material-ui/core';
import React from 'react';

const OrganizationsIcon = (props) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <path
        d="M8.24992 17.4167H10.9999V11.8631L7.33325 8.66618L3.66659 11.8631V17.4167H6.41658V13.75H8.24992V17.4167ZM19.2499 19.25H2.74992C2.24366 19.25 1.83325 18.8396 1.83325 18.3333V11.4462C1.83325 11.1813 1.94784 10.9293 2.1475 10.7552L5.49992 7.83232V3.66667C5.49992 3.16041 5.91033 2.75 6.41658 2.75H19.2499C19.7562 2.75 20.1666 3.16041 20.1666 3.66667V18.3333C20.1666 18.8396 19.7562 19.25 19.2499 19.25ZM14.6666 10.0833V11.9167H16.4999V10.0833H14.6666ZM14.6666 13.75V15.5833H16.4999V13.75H14.6666ZM14.6666 6.41667V8.25H16.4999V6.41667H14.6666ZM10.9999 6.41667V8.25H12.8333V6.41667H10.9999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default OrganizationsIcon;
