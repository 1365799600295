import { Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  active: {
    '& $icon': {
      color: theme.palette.secondary.main
    },
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    color: theme.palette.secondary.main
  },
  button: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  buttonLeaf: {
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    height: 24,
    marginRight: theme.spacing(1),
    width: 24
  },
  item: {
    display: 'block',
    paddingBottom: 0,
    paddingTop: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingBottom: 0,
    paddingTop: 0
  },
  title: {
    fontSize: 14,
    marginRight: 'auto'
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon &&
            (typeof Icon === 'string' ? (
              <div
                style={{
                  WebkitMask: `url(${Icon}) no-repeat center / contain`,
                  backgroundColor: '#546e7a',
                  mask: `url(${Icon}) no-repeat center / contain`
                }}
                className={classes.icon}
              />
            ) : (
              <Icon className={classes.icon} size="20" />
            ))}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon &&
          (typeof Icon === 'string' ? (
            <div
              style={{
                WebkitMask: `url(${Icon}) no-repeat center / contain`,
                backgroundColor: '#546e7a',
                mask: `url(${Icon}) no-repeat center / contain`
              }}
              className={classes.icon}
            />
          ) : (
            <Icon className={classes.icon} size="20" />
          ))}
        <span className={classes.title}>{title}</span>
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
