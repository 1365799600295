/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const navItems = [
  {
    href: '/docs/welcome',
    title: 'Welcome'
  },
  {
    href: '/docs/getting-started',
    title: 'Getting Started'
  },
  {
    href: '/docs/environment-variables',
    title: 'Environment Variables'
  },
  {
    href: '/docs/deployment',
    title: 'Deployment'
  },
  {
    href: '/docs/analytics',
    title: 'Analytics'
  },
  {
    href: '/docs/api-calls',
    title: 'Api Calls'
  },
  {
    href: '/docs/authentication',
    title: 'Authentication'
  },
  {
    href: '/docs/routing',
    title: 'Routing'
  },
  {
    href: '/docs/settings',
    title: 'Settings'
  },
  {
    href: '/docs/state-management',
    title: 'State Management'
  },
  {
    href: '/docs/theming',
    title: 'Theming'
  },
  {
    href: '/docs/support',
    title: 'Support'
  },
  {
    href: '/docs/changelog',
    title: 'Changelog'
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  if (item.items) {
    acc.push(
      <NavItem depth={depth} key={item.href} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        key={item.href}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  desktopDrawer: {
    height: 'calc(100% - 64px)',
    top: 64,
    width: 256
  },
  mobileDrawer: {
    width: 256
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={2}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>{renderNavItems({ items: navItems })}</Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
