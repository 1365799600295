import { get } from 'lodash';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';

export default function useConfiguration() {
  const { data, loading } = useQuery(gql`
    query Configuration {
      accountName: Configuration(name: "account_name") {
        value
      }
      appUrl: Configuration(name: "app_url") {
        value
      }
      hideExtraAdminPages: Configuration(name: "hideExtraAdminPages") {
        value
      }
      hideBlogPages: Configuration(name: "hideBlogPages") {
        value
      }
      isVerifyCheckForUpdate: Configuration(name: "isVerifyCheckForUpdate") {
        value
      }
      ConfigurationCustomerHourlyVoluntary: Configuration(
        name: "ConfigurationCustomerHourlyVoluntary"
      ) {
        value
      }
      ConfigurationEmployeeHourlyVoluntary: Configuration(
        name: "ConfigurationEmployeeHourlyVoluntary"
      ) {
        value
      }
      ConfigurationHideOrganisationsDirectory: Configuration(
        name: "hideOrganisationsDirectory"
      ) {
        value
      }
      getDirectorateAndTeamNames: getDirectorateAndTeamNames {
        directorateName
        directorateTeamName
        directorateNamePlural
        directorateTeamNamePlural
      }
    }
  `);

  return {
    ConfigurationHideOrganisationsDirectory:
      get(data, 'ConfigurationHideOrganisationsDirectory.value', 'false') ===
      'true',
    accountName: get(data, 'accountName.value', 'Goodsted'),
    appUrl: get(data, 'appUrl.value', 'https://app.goodsted.com/'),
    customerHourlyRate: get(
      data,
      'ConfigurationCustomerHourlyVoluntary.value',
      null
    ),
    directorateAndTeamNames: get(data, 'getDirectorateAndTeamNames', {
      directorateName: 'Directorate',
      directorateNamePlural: 'Directorates',
      directorateTeamName: 'Team',
      directorateTeamNamePlural: 'Teams'
    }),
    employeeHourlyRate: get(
      data,
      'ConfigurationEmployeeHourlyVoluntary.value',
      null
    ),
    hideBlogPages:
      loading || get(data, 'hideBlogPages.value', 'false') === 'true',
    hideExtraAdminPages:
      loading || get(data, 'hideExtraAdminPages.value', 'false') === 'true',
    isVerifyCheckForUpdate:
      get(data, 'isVerifyCheckForUpdate.value', false) === 'true'
  };
}
