import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { THEMES } from 'src/constants';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import Account from './Account';
import Logo from 'src/components/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import _, { get } from 'lodash';
import clsx from 'clsx';
// import Settings from './Settings';

const useStyles = makeStyles(theme => ({
  mainLogo: {
    height: 40,
    marginLeft: '3rem',
    maxWidth: 96,
    objectFit: 'contain'
  },
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none'
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const Configuration = useQuery(gql`
    query Configuration {
      primaryMain: Configuration(name: "primaryMain") {
        value
      }
    }
  `);
  const primaryMain = get(Configuration, 'data.primaryMain.value', '#ffffff');

  return (
    <AppBar
      className={clsx(classes.root, className)}
      style={{ background: primaryMain }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="textPrimary"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo className={classes.mainLogo} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search/> */}
        {/* <Contacts/> */}
        {/* <Notifications/> */}
        {/* <Settings/> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
