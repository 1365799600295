import 'nprogress/nprogress.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import 'src/mock';
import * as serviceWorker from 'src/serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
import { SettingsProvider } from 'src/context/SettingsContext';
import { client } from './graphql/client';
import { configureStore } from 'src/store';
import { enableES5 } from 'immer';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';
import React from 'react';
import ReactDOM from 'react-dom';

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
