/* eslint-disable react/no-array-index-key */
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import React, { Fragment, Suspense, lazy } from 'react';

const routesConfig = [
  {
    component: () => <Redirect to="/login" />,
    exact: true,
    path: '/'
  },
  {
    component: lazy(() => import('src/views/pages/Error404View')),
    exact: true,
    path: '/404'
  },
  {
    component: lazy(() => import('src/views/auth/LoginView')),
    exact: true,
    guard: GuestGuard,
    path: '/login'
  },

  {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app',
    routes: [
      // REPORTS
      {
        component: () => <Redirect to="/app/reports/overview" />,
        exact: true,
        path: '/app'
      },
      {
        component: () => <Redirect to="/app/reports/overview" />,
        exact: true,
        path: '/app/reports'
      },
      {
        component: lazy(() => import('src/views/reports/OverviewView')),
        exact: true,
        path: '/app/reports/overview'
      },
      {
        component: lazy(() => import('src/views/reports/ColleaguesView')),
        exact: true,
        path: '/app/reports/colleagues'
      },
      {
        component: lazy(() =>
          import('src/views/reports/CommunityPartnersView')
        ),
        exact: true,
        path: '/app/reports/community-partners'
      },
      {
        component: lazy(() => import('src/views/reports/OpportunitiesView')),
        exact: true,
        path: '/app/reports/opportunities'
      },
      {
        component: lazy(() => import('src/views/reports/CustomersView')),
        exact: true,
        path: '/app/reports/customers'
      },
      {
        component: lazy(() => import('src/views/reports/ContractorsView')),
        exact: true,
        path: '/app/reports/contractors'
      },
      {
        component: lazy(() => import('src/views/reports/DonorsView')),
        exact: true,
        path: '/app/reports/donors'
      },
      {
        component: lazy(() => import('src/views/reports/VolunteersView')),
        exact: true,
        path: '/app/reports/volunteers'
      },
      {
        component: lazy(() => import('src/views/reports/BeneficiariesView')),
        exact: true,
        path: '/app/reports/beneficiaries'
      },
      {
        component: lazy(() => import('src/views/reports/ChallengesView')),
        exact: true,
        path: '/app/reports/challenges'
      },

      // MANAGEMENT
      {
        component: () => <Redirect to="/app/management/members" />,
        exact: true,
        path: '/app/management'
      },
      {
        component: lazy(() => import('src/views/management/MemberListView')),
        exact: true,
        path: '/app/management/members'
      },
      {
        component: lazy(() => import('src/views/management/MemberEditView')),
        exact: true,
        path: '/app/management/members/edit/:id'
      },
      {
        component: lazy(() => import('src/views/management/MemberDetailsView')),
        exact: true,
        path: '/app/management/members/:id'
      },
      {
        component: lazy(() => import('src/views/management/GroupCreateView')),
        exact: true,
        path: '/app/management/groups/create'
      },
      {
        component: lazy(() => import('src/views/management/GroupEditView')),
        exact: true,
        path: '/app/management/groups/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/GroupMemberEditView')
        ),
        exact: true,
        path: '/app/management/group-members/edit/:id'
      },
      {
        component: lazy(() => import('src/views/management/GroupDetailsView')),
        exact: true,
        path: '/app/management/groups/:id'
      },
      {
        component: lazy(() => import('src/views/management/GroupListView')),
        exact: true,
        path: '/app/management/groups'
      },
      {
        component: lazy(() => import('src/views/management/GroupListView')),
        exact: true,
        path: '/app/management/groups-new'
      },
      {
        component: lazy(() => import('src/views/management/GroupListView')),
        exact: true,
        path: '/app/management/projects'
      },

      {
        component: lazy(() => import('src/views/management/TeamEditView')),
        exact: true,
        path: '/app/management/teams/edit/:id'
      },
      {
        component: lazy(() => import('src/views/management/TeamCreateView')),
        exact: true,
        path: '/app/management/teams/create/:id'
      },
      {
        component: lazy(() => import('src/views/management/TeamDetailsView')),
        exact: true,
        path: '/app/management/teams/:id'
      },

      {
        component: lazy(() =>
          import('src/views/management/DirectorateEditView')
        ),
        exact: true,
        path: '/app/management/directorates/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/DirectorateCreateView')
        ),
        exact: true,
        path: '/app/management/directorates/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/DirectorateDetailsView')
        ),
        exact: true,
        path: '/app/management/directorates/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/DirectorateListView')
        ),
        exact: true,
        path: '/app/management/directorates'
      },
      {
        component: lazy(() =>
          import('src/views/management/ChallengeCreateView')
        ),
        exact: true,
        path: '/app/management/challenges/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/ChallengeDetailsView')
        ),
        exact: true,
        path: '/app/management/challenges/:id'
      },
      {
        component: lazy(() => import('src/views/management/ChallengeEditView')),
        exact: true,
        path: '/app/management/challenges/edit/:id'
      },
      {
        component: lazy(() => import('src/views/management/DonationListView')),
        exact: true,
        path: '/app/management/donations'
      },
      {
        component: lazy(() =>
          import('src/views/management/DonationCreateView')
        ),
        exact: true,
        path: '/app/management/donations/create'
      },
      {
        component: lazy(() => import('src/views/management/DonationEditView')),
        exact: true,
        path: '/app/management/donations/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/DonationTypeListView')
        ),
        exact: true,
        path: '/app/management/donationTypes'
      },
      {
        component: lazy(() =>
          import('src/views/management/DonationTypeCreateView')
        ),
        exact: true,
        path: '/app/management/donationTypes/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/DonationTypeEditView')
        ),
        exact: true,
        path: '/app/management/donationTypes/edit/:id'
      },

      {
        component: lazy(() => import('src/views/management/BlogListView')),
        exact: true,
        path: '/app/management/blogs'
      },
      {
        component: lazy(() => import('src/views/management/BlogCreateView')),
        exact: true,
        path: '/app/management/blogs/create'
      },
      {
        component: lazy(() => import('src/views/management/BlogEditView')),
        exact: true,
        path: '/app/management/blogs/edit/:id'
      },

      {
        component: lazy(() => import('src/views/management/ChallengeListView')),
        exact: true,
        path: '/app/management/challenges'
      },

      {
        component: lazy(() => import('src/views/management/MessagesListView')),
        exact: true,
        path: '/app/management/messages'
      },
      {
        component: lazy(() => import('src/views/chat/ChatView')),
        exact: true,
        path: '/app/management/messages/:threadKey'
      },

      {
        component: lazy(() => import('src/views/management/SkillsListView')),
        exact: true,
        path: '/app/management/skills'
      },
      {
        component: lazy(() => import('src/views/management/UpdatesListView')),
        exact: true,
        path: '/app/management/updates'
      },
      {
        component: lazy(() => import('src/views/management/UpdateEditView')),
        exact: true,
        path: '/app/management/updates/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/ConfigurationInlineEditView')
        ),
        exact: true,
        path: '/app/management/configurations'
      },
      {
        component: lazy(() =>
          import('src/views/management/ConfigurationCreateView')
        ),
        exact: true,
        path: '/app/management/configurations/create'
      },
      {
        component: lazy(() => import('src/views/management/CausesListView')),
        exact: true,
        path: '/app/management/causes'
      },
      {
        component: lazy(() => import('src/views/management/CauseEditView')),
        exact: true,
        path: '/app/management/causes/edit/:id'
      },
      {
        component: lazy(() => import('src/views/management/SupportsListView')),
        exact: true,
        path: '/app/management/supports'
      },
      {
        component: lazy(() => import('src/views/management/OfferDetailsView')),
        exact: true,
        path: '/app/management/supports/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/ManualVolunteerCreateView')
        ),
        exact: true,
        path: '/app/management/manual-volunteers/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/ManualVolunteerEditView')
        ),
        exact: true,
        path: '/app/management/manual-volunteers/:id/edit'
      },
      {
        component: lazy(() =>
          import('src/views/management/ManualVolunteersListView')
        ),
        exact: true,
        path: '/app/management/manual-volunteers'
      },
      {
        component: lazy(() =>
          import('src/views/management/ManualVolunteerDetail')
        ),
        exact: true,
        path: '/app/management/manual-volunteers/:id'
      },

      {
        component: lazy(() => import('src/views/management/BlogCreateView')),
        exact: true,
        path: '/app/management/blogs/create'
      },
      {
        component: lazy(() => import('src/views/pages/AccountView')),
        exact: true,
        path: '/app/account'
      },
      {
        component: lazy(() =>
          import('src/views/management/PricingRequestView')
        ),
        exact: true,
        path: '/app/management/pricing-requests'
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    layout: DocsLayout,
    path: '/docs',
    routes: [
      {
        component: () => <Redirect to="/docs/welcome" />,
        exact: true,
        path: '/docs'
      },
      {
        component: lazy(() => import('src/views/docs/WelcomeView')),
        exact: true,
        path: '/docs/welcome'
      },
      {
        component: lazy(() => import('src/views/docs/GettingStartedView')),
        exact: true,
        path: '/docs/getting-started'
      },
      {
        component: lazy(() =>
          import('src/views/docs/EnvironmentVariablesView')
        ),
        exact: true,
        path: '/docs/environment-variables'
      },
      {
        component: lazy(() => import('src/views/docs/DeploymentView')),
        exact: true,
        path: '/docs/deployment'
      },
      {
        component: lazy(() => import('src/views/docs/ApiCallsView')),
        exact: true,
        path: '/docs/api-calls'
      },
      {
        component: lazy(() => import('src/views/docs/AnalyticsView')),
        exact: true,
        path: '/docs/analytics'
      },
      {
        component: lazy(() => import('src/views/docs/AuthenticationView')),
        exact: true,
        path: '/docs/authentication'
      },
      {
        component: lazy(() => import('src/views/docs/RoutingView')),
        exact: true,
        path: '/docs/routing'
      },
      {
        component: lazy(() => import('src/views/docs/SettingsView')),
        exact: true,
        path: '/docs/settings'
      },
      {
        component: lazy(() => import('src/views/docs/StateManagementView')),
        exact: true,
        path: '/docs/state-management'
      },
      {
        component: lazy(() => import('src/views/docs/ThemingView')),
        exact: true,
        path: '/docs/theming'
      },
      {
        component: lazy(() => import('src/views/docs/SupportView')),
        exact: true,
        path: '/docs/support'
      },
      {
        component: lazy(() => import('src/views/docs/ChangelogView')),
        exact: true,
        path: '/docs/changelog'
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
