import { SvgIcon } from '@material-ui/core';
import React from 'react';

const MembersIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 19" {...props}>
      <path d="M12.5925 13.1378L12.7526 13.2964L12.9146 13.1378C13.1046 12.9505 13.3303 12.802 13.5787 12.7007C13.827 12.5993 14.0932 12.5472 14.362 12.5472C14.6309 12.5472 14.8971 12.5993 15.1454 12.7007C15.3938 12.802 15.6194 12.9505 15.8095 13.1378C15.9996 13.325 16.1504 13.5473 16.2533 13.792C16.3561 14.0366 16.4091 14.2989 16.4091 14.5637C16.4091 14.8285 16.3561 15.0907 16.2533 15.3354C16.1504 15.58 15.9996 15.8023 15.8095 15.9896L12.7526 19L9.69751 15.9896C9.31362 15.6114 9.09795 15.0985 9.09795 14.5637C9.09795 14.0289 9.31362 13.516 9.69751 13.1378C10.0814 12.7596 10.6021 12.5472 11.145 12.5472C11.6879 12.5472 12.2086 12.7596 12.5925 13.1378ZM7.27837 11.6509V18.8208H0C2.1934e-05 16.958 0.735968 15.1684 2.05202 13.8308C3.36807 12.4933 5.1609 11.7127 7.05092 11.6545L7.27837 11.6509ZM7.27837 0C10.2943 0 12.7371 2.40637 12.7371 5.37736C12.7371 8.34835 10.2943 10.7547 7.27837 10.7547C4.26239 10.7547 1.81959 8.34835 1.81959 5.37736C1.81959 2.40637 4.26239 0 7.27837 0Z" />
    </SvgIcon>
  );
};

export default MembersIcon;
