import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgb(255,229,100)',
    color: '#1a1a1a',
    fontFamily:
      "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
    fontSize: 14,
    paddingLeft: 2,
    paddingRight: 2
  }
}));

function CodeInline(props) {
  const classes = useStyles();

  return <code className={classes.root} {...props} />;
}

export default CodeInline;
