import { v4 as uuidv4 } from 'uuid';
import mock from 'src/utils/mock';
import moment from 'moment';

mock.onGet('/api/management/members').reply(200, {
  customers: [
    {
      acceptsMarketing: false,
      avatar: '/static/images/avatars/avatar_2.png',
      currency: '$',
      email: 'ekaterina.tankova@devias.io',
      id: '5e887a62195cc5aef7e8ca5d',
      isProspect: false,
      isReturning: false,
      location: 'West Virginia, USA',
      name: 'Ekaterina Tankova',
      orders: 1,
      spent: '500.00',
      updatedAt: moment()
        .subtract(1, 'days')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_3.png',
      currency: '$',
      email: 'cao.yu@devias.io',
      id: '5e887ac47eed253091be10cb',
      isProspect: false,
      isReturning: true,
      location: 'Bristow',
      name: 'Cao Yu',
      orders: 3,
      spent: '300.00',
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_4.png',
      currency: '$',
      email: 'alex.richardson@devias.io',
      id: '5e887b209c28ac3dd97f6db5',
      isProspect: true,
      isReturning: false,
      location: 'Georgia, USA',
      name: 'Alex Richardson',
      orders: 0,
      spent: '0.00',
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(1, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: false,
      avatar: '/static/images/avatars/avatar_5.png',
      currency: '$',
      email: 'anje.keizer@devias.io',
      id: '5e887b7602bdbc4dbb234b27',
      isProspect: false,
      isReturning: false,
      location: 'Ohio, USA',
      name: 'Anje Keizer',
      orders: 6,
      spent: '5,600.00',
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(4, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_6.png',
      currency: '$',
      email: 'katarina.smith@devias.io',
      id: '5e86809283e28b96d2d38537',
      isProspect: false,
      isReturning: true,
      location: 'Texas, USA',
      name: 'Katarina Smith',
      orders: 1,
      spent: '500.00',
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(11, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_7.png',
      currency: '$',
      email: 'adam.denisov@devias.io',
      id: '5e86805e2bafd54f66cc95c3',
      isProspect: true,
      isReturning: false,
      location: 'California, USA',
      name: 'Adam Denisov',
      orders: 0,
      spent: '0.00',
      updatedAt: moment()
        .subtract(3, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: false,
      avatar: '/static/images/avatars/avatar_8.png',
      currency: '$',
      email: 'miller.edwards@devias.io',
      id: '5e887a1fbefd7938eea9c981',
      isProspect: true,
      isReturning: false,
      location: 'California, USA',
      name: 'Miller Edwards',
      orders: 0,
      spent: '0.00',
      updatedAt: moment()
        .subtract(4, 'days')
        .subtract(5, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_9.png',
      currency: '$',
      email: 'emilee.simchenko@devias.io',
      id: '5e887d0b3d090c1b8f162003',
      isProspect: false,
      isReturning: false,
      location: 'Nevada, USA',
      name: 'Emilee Simchenko',
      orders: 4,
      spent: '100.00',
      updatedAt: moment()
        .subtract(4, 'days')
        .subtract(15, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_10.png',
      currency: '$',
      email: 'elliott.stone@devias.io',
      id: '5e88792be2d4cfb4bf0971d9',
      isProspect: false,
      isReturning: true,
      location: 'Michigan, USA',
      name: 'Elliott Stone',
      orders: 2,
      spent: '1,000.00',
      updatedAt: moment()
        .subtract(5, 'days')
        .subtract(2, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: true,
      avatar: '/static/images/avatars/avatar_11.png',
      email: 'shen.zhi@devias.io',
      id: '5e8877da9a65442b11551975',
      isProspect: true,
      isReturning: false,
      location: 'Utah, USA',
      name: 'Shen Zhi',
      orders: 0,
      spent: '0.00',
      updatedAt: moment()
        .subtract(6, 'days')
        .subtract(8, 'hours')
        .toDate()
        .getTime()
    },
    {
      acceptsMarketing: false,
      avatar: '/static/images/avatars/avatar_12.png',
      currency: '$',
      email: 'merrile.burgett@devias.io',
      id: '5e8680e60cba5019c5ca6fda',
      isProspect: false,
      isReturning: true,
      location: 'Utah, USA',
      name: 'Merrile Burgett',
      orders: 7,
      spent: '200.00',
      updatedAt: moment()
        .subtract(9, 'days')
        .subtract(1, 'hours')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/management/members/1').reply(200, {
  customer: {
    address1: 'Street John Wick, no. 7',
    address2: 'House #25',
    autoCC: false,
    balance: 0,
    country: 'United States',
    creditCard: '4142 **** **** **** ****',
    currency: '$',
    email: 'ekaterina@devias.io',
    fullName: 'Ekaterina Tankova',
    phone: '+55 748 327 439',
    state: 'Alabama',
    vat: 19,
    verified: true,
    zipCode: '240355'
  }
});

mock.onGet('/api/management/members/1/emails').reply(200, {
  emails: [
    {
      createdAt: moment()
        .subtract(3, 'days')
        .subtract(5, 'hours')
        .subtract(34, 'minutes')
        .toDate()
        .getTime(),
      description: 'Order confirmation',
      id: uuidv4()
    },
    {
      createdAt: moment()
        .subtract(4, 'days')
        .subtract(11, 'hours')
        .subtract(49, 'minutes')
        .toDate()
        .getTime(),
      description: 'Order confirmation',
      id: uuidv4()
    }
  ]
});

mock.onGet('/api/management/members/1/invoices').reply(200, {
  invoices: [
    {
      currency: '$',
      date: moment()
        .toDate()
        .getTime(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      id: uuidv4(),
      paymentMethod: 'Credit Card',
      status: 'paid',
      value: '5.25'
    },
    {
      currency: '$',
      date: moment()
        .toDate()
        .getTime(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      id: uuidv4(),
      paymentMethod: 'Credit Card',
      status: 'paid',
      value: '5.25'
    }
  ]
});

mock.onGet('/api/management/members/1/logs').reply(200, {
  logs: [
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Purchase',
      id: uuidv4(),
      method: 'POST',
      route: '/api/purchase',
      status: 200
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Purchase',
      error: 'Invalid credit card',
      id: uuidv4(),
      method: 'POST',
      route: '/api/purchase',
      status: 522
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(8, 'minutes')
        .subtract(23, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Cart remove',
      id: uuidv4(),
      method: 'DELETE',
      route: '/api/products/d65654e/remove',
      status: 200
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(20, 'minutes')
        .subtract(54, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Cart add',
      id: uuidv4(),
      method: 'GET',
      route: '/api/products/d65654e/add',
      status: 200
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(34, 'minutes')
        .subtract(16, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Cart add',
      id: uuidv4(),
      method: 'GET',
      route: '/api/products/c85727f/add',
      status: 200
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(54, 'minutes')
        .subtract(30, 'seconds')
        .toDate()
        .getTime(),
      desc: 'View product',
      id: uuidv4(),
      method: 'GET',
      route: '/api/products/c85727f',
      status: 200
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(56, 'minutes')
        .subtract(40, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Get products',
      id: uuidv4(),
      method: 'GET',
      route: '/api/products',
      status: 200
    },
    {
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(57, 'minutes')
        .subtract(5, 'seconds')
        .toDate()
        .getTime(),
      desc: 'Login',
      id: uuidv4(),
      method: 'POST',
      route: '/api/login',
      status: 200
    }
  ]
});

mock.onGet('/api/management/products').reply(200, {
  products: [
    {
      attributes: ['Cotton'],
      category: 'dress',
      createdAt: moment()
        .subtract(1, 'days')
        .toDate()
        .getTime(),
      currency: '$',
      id: uuidv4(),
      image: '',
      inventoryType: 'in_stock',
      isAvailable: true,
      isShippable: false,
      name: 'Charlie Tulip Dress',
      price: 23.99,
      quantity: 85,
      updatedAt: moment()
        .subtract(6, 'hours')
        .toDate()
        .getTime(),
      variants: 2
    },
    {
      attributes: ['Cotton'],
      category: 'dress',
      createdAt: moment()
        .subtract(3, 'days')
        .toDate()
        .getTime(),
      currency: '$',
      id: uuidv4(),
      image: '',
      inventoryType: 'out_of_stock',
      isAvailable: false,
      isShippable: true,
      name: 'Kate Leopard Dress',
      price: 95.0,
      quantity: 0,
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(8, 'hours')
        .toDate()
        .getTime(),
      variants: 1
    },
    {
      attributes: ['Variety of styles'],
      category: 'jewelry',
      createdAt: moment()
        .subtract(6, 'days')
        .toDate()
        .getTime(),
      currency: '$',
      id: uuidv4(),
      image: '',
      inventoryType: 'in_stock',
      isAvailable: true,
      isShippable: false,
      name: 'Layering Bracelets Collection',
      price: 155.0,
      quantity: 48,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(2, 'hours')
        .toDate()
        .getTime(),
      variants: 5
    },
    {
      attributes: ['Polyester and Spandex'],
      category: 'blouse',
      createdAt: moment()
        .subtract(12, 'days')
        .toDate()
        .getTime(),
      currency: '$',
      id: uuidv4(),
      image: '',
      inventoryType: 'limited',
      isAvailable: false,
      isShippable: true,
      name: 'Flared Sleeve Floral Blouse',
      price: 17.99,
      quantity: 5,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      variants: 1
    }
  ]
});

mock.onGet('/api/management/orders').reply(200, {
  orders: [
    {
      createdAt: moment()
        .subtract(10, 'minutes')
        .toDate()
        .getTime(),
      customer: {
        name: 'Ekaterina Tankova'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'CreditCard',
        ref: 'FAD103',
        status: 'pending',
        total: '500.00'
      },
      status: 'inactive'
    },
    {
      createdAt: moment()
        .subtract(32, 'minutes')
        .subtract(23, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Cao Yu'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'CreditCard',
        ref: 'FAD102',
        status: 'pending',
        total: '500.00'
      },
      status: 'inactive'
    },
    {
      createdAt: moment()
        .subtract(36, 'minutes')
        .subtract(51, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Alex Richardson'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'PayPal',
        ref: 'FAD101',
        status: 'completed',
        total: '500.00'
      },
      status: 'active'
    },
    {
      createdAt: moment()
        .subtract(38, 'minutes')
        .subtract(55, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Anje Keizer'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'CreditCard',
        ref: 'FAD100',
        status: 'pending',
        total: '500.00'
      },
      status: 'inactive'
    },
    {
      createdAt: moment()
        .subtract(40, 'minutes')
        .subtract(3, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Clarke Gillebert'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'PayPal',
        ref: 'FAD99',
        status: 'completed',
        total: '500.00'
      },
      status: 'active'
    },
    {
      createdAt: moment()
        .subtract(45, 'minutes')
        .subtract(32, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Adam Denisov'
      },
      id: '5e86805e2bafd54f66cc95c3',
      payment: {
        currency: '$',
        method: 'PayPal',
        ref: 'FAD98',
        status: 'completed',
        total: '500.00'
      },
      status: 'active'
    },
    {
      createdAt: moment()
        .subtract(48, 'minutes')
        .subtract(57, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        avatar: '/static/images/avatars/avatar_8.png',
        id: '5e887a1fbefd7938eea9c981',
        name: 'Miller Edwards'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'CreditCard',
        ref: 'FAD97',
        status: 'pending',
        total: '500.00'
      },
      status: 'inactive'
    },
    {
      createdAt: moment()
        .subtract(49, 'minutes')
        .subtract(4, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Emilee Simchenko'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'CreditCard',
        ref: 'FAD96',
        status: 'completed',
        total: '500.00'
      },
      status: 'active'
    },
    {
      createdAt: moment()
        .subtract(57, 'minutes')
        .subtract(43, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Elliott Stone'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'PayPal',
        ref: 'FAD95',
        status: 'rejected',
        total: '500.00'
      },
      status: 'inactive'
    },
    {
      createdAt: moment()
        .subtract(59, 'minutes')
        .subtract(6, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Shen Zhi'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'CreditCard',
        ref: 'FAD94',
        status: 'canceled',
        total: '500.00'
      },
      status: 'inactive'
    },
    {
      createdAt: moment()
        .subtract(1, 'hour')
        .subtract(15, 'minutes')
        .subtract(43, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Merrile Burgett'
      },
      id: uuidv4(),
      payment: {
        currency: '$',
        method: 'PayPal',
        ref: 'FAD93',
        status: 'canceled',
        total: '500.00'
      },
      status: 'inactive'
    }
  ]
});

mock.onGet('/api/management/orders/1').reply(200, {
  order: {
    createdAt: moment()
      .toDate()
      .getTime(),
    currency: '$',
    customer: {
      address: 'Street King William, 42456',
      city: 'Montgomery',
      country: 'United States',
      name: 'Ekaterina Tankova'
    },
    id: uuidv4(),
    items: [
      {
        billing: 'monthly',
        cuantity: 25,
        currency: '$',
        id: uuidv4(),
        name: 'Project Points',
        status: 'completed',
        value: '50.25'
      },
      {
        billing: 'monthly',
        cuantity: 1,
        currency: '$',
        id: uuidv4(),
        name: 'Freelancer Subscription',
        status: 'completed',
        value: '5.00'
      }
    ],
    promoCode: null,
    ref: 'FAD107',
    status: 'canceled',
    value: '55.25'
  }
});

mock.onGet('/api/management/invoices').reply(200, {
  invoices: [
    {
      amount: 65.28,
      createdAt: moment()
        .subtract(1, 'hours')
        .toDate()
        .getTime(),
      currency: '$',
      customer: {
        email: 'contact@anahenisky.io',
        name: 'Ana Henisky'
      },
      id: '28382843',
      status: 'paid'
    },
    {
      amount: 253.76,
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(5, 'hours')
        .toDate()
        .getTime(),
      currency: '$',
      customer: {
        email: 'sales@matt-jason.com',
        name: 'Matt Jason'
      },
      id: '28382845',
      status: 'pending'
    },
    {
      amount: 781.5,
      createdAt: moment()
        .subtract(6, 'days')
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      currency: '$',
      customer: {
        email: 'support@terrythomas.io',
        name: 'Terry Thomas'
      },
      id: '28382345',
      status: 'canceled'
    },
    {
      amount: 96.64,
      createdAt: moment()
        .subtract(15, 'days')
        .subtract(2, 'hours')
        .toDate()
        .getTime(),
      currency: '$',
      customer: {
        email: 'contact@triv-shopper.co.uk',
        name: 'Triv Shopper'
      },
      id: '283823653',
      status: 'paid'
    }
  ]
});

mock.onGet('/api/management/invoices/1').reply(200, {
  invoice: {
    createdAt: moment()
      .toDate()
      .getTime(),
    currency: '$',
    customer: {
      address: '271 Richmond Rd, Grey Lynn, Auckland 1022, New Zealand',
      company: 'Countdown Grey Lynn',
      name: 'Tracey Herman',
      taxId: '6934656584231'
    },
    dueDate: moment()
      .toDate()
      .getTime(),
    id: '765ed153',
    issueDate: moment()
      .add(15, 'days')
      .toDate()
      .getTime(),
    products: [
      {
        desc: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
        id: uuidv4(),
        value: '55.50'
      }
    ],
    ref: 'DEV-9483',
    subtotal: '50.00',
    taxes: '5.50',
    total: '55.50'
  }
});
