/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { THEMES } from '../constants';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { defaults } from 'react-chartjs-2';
import { gql } from 'apollo-boost';
import { softShadows } from './shadows';
import { useQuery } from 'react-apollo';
import PoppinsBold from './font/Poppins-Bold.ttf';
import PoppinsRegular from './font/Poppins-Regular.ttf';
import PoppinsSemiBold from './font/Poppins-SemiBold.ttf';
import _, { get } from 'lodash';
import typography from './typography';

const baseConfig = {
  direction: 'ltr',
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    }
  },
  typography
};

const poppinsRegular = {
  fontFamily: 'Poppins-Regular',
  fontStyle: 'normal',
  src: `

      local('Poppins-Regular'),

      url(${PoppinsRegular}) format('truetype')
    `
};

const poppinsBold = {
  fontFamily: 'Poppins-Bold',
  fontStyle: 'normal',
  src: `

      local('Poppins-Bold'),

      url(${PoppinsBold}) format('truetype')
    `
};

const poppinsSemiBold = {
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  src: `

      local('Poppins-SemiBold'),

      url(${PoppinsSemiBold}) format('truetype')
    `
};

export function useTheme(settings = {}) {
  defaults.global.defaultFontFamily = 'Poppins-Regular';
  const Configuration = useQuery(gql`
    query Configuration {
      primaryMain: Configuration(name: "primaryMain") {
        value
      }
      textPrimary: Configuration(name: "textPrimary") {
        value
      }
      textSecondary: Configuration(name: "textSecondary") {
        value
      }
    }
  `);
  const textPrimary = get(
    Configuration,
    'data.textPrimary.value',
    colors.blueGrey[900]
  );
  const textSecondary = get(
    Configuration,
    'data.textSecondary.value',
    colors.blueGrey[600]
  );
  const primaryMain = get(Configuration, 'data.primaryMain.value', '#E76E3C');
  const customFontFamily = [];
  customFontFamily.push(poppinsRegular);
  customFontFamily.push(poppinsBold);
  customFontFamily.push(poppinsSemiBold);
  const themeConfigs = [
    {
      name: THEMES.LIGHT,
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': customFontFamily,
            video: {
              outline: 'none'
            }
          }
        },
        MuiInputBase: {
          input: {
            '&::placeholder': {
              color: colors.blueGrey[600],
              opacity: 1
            }
          }
        }
      },
      palette: {
        action: {
          active: colors.blueGrey[600]
        },
        background: {
          dark: '#f4f6f8',
          default: colors.common.white,
          paper: colors.common.white
        },
        primary: {
          main: colors.indigo[600]
        },
        secondary: {
          main: primaryMain
        },
        text: {
          primary: textPrimary,
          secondary: textSecondary
        },
        type: 'light'
      },
      shadows: softShadows
    }
  ];
  let themeConfig = themeConfigs.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
