import mock from 'src/utils/mock';
import moment from 'moment';

mock.onGet('/api/social/profile').reply(200, {
  user: {
    avatar: '/static/images/avatars/avatar_6.png',
    bio: 'Sales Manager',
    connectedStatus: 'not_connected',
    cover: '/static/images/covers/cover_1.jpg',
    currentCity: 'Bucharest',
    currentJob: {
      company: 'Devias IO',
      title: 'Product Designer'
    },
    email: 'katarina.smith@devias.io',
    id: '5e86809283e28b96d2d38537',
    name: 'Katarina Smith',
    originCity: 'Rm. Valcea',
    previousJob: {
      company: 'Focus Aesthetic Dyanmics',
      title: 'UX Designer at'
    },
    profileProgress: 50,
    quote:
      'Everyone thinks of changing the world, but no one thinks of changing himself.'
  }
});

mock.onGet('/api/social/feed').reply(200, {
  posts: [
    {
      author: {
        avatar: '/static/images/avatars/avatar_10.png',
        id: '5e88792be2d4cfb4bf0971d9',
        name: 'Elliott Stone'
      },
      comments: [
        {
          author: {
            avatar: '/static/images/avatars/avatar_12.png',
            id: '5e8680e60cba5019c5ca6fda',
            name: 'Merrile Burgett'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fb6c648772b52f860a8',
          message: "I've been using Angular for the past 3 years"
        }
      ],
      createdAt: moment()
        .subtract(16, 'minutes')
        .toDate()
        .getTime(),
      id: '5e887fa38598b6fe61667757',
      liked: true,
      likes: 1,
      message: "Hey guys! What's your favorite framework?"
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_6.png',
        id: '5e86809283e28b96d2d38537',
        name: 'Katarina Zhi'
      },
      comments: [
        {
          author: {
            avatar: '/static/images/avatars/avatar_5.png',
            id: '5e887b7602bdbc4dbb234b27',
            name: 'Anje Keizer'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fc17162ba254da30771',
          message: 'Could use some more statistics, but that’s me haha'
        },
        {
          author: {
            avatar: '/static/images/avatars/avatar_8.png',
            id: '5e887a1fbefd7938eea9c981',
            name: 'Miller Edwards'
          },
          createdAt: moment()
            .subtract(2, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fc759bebe8d5d54a2e5',
          message:
            'Hmm, honestly this looks nice but I would change the shadow though'
        }
      ],
      createdAt: moment()
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      id: '5e887faca2b7a1ddce01221a',
      liked: true,
      likes: 24,
      media: '/static/images/posts/post_1.png',
      message: 'Just made this home screen for a project, what-cha thinkin?'
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_6.png',
        id: '5e86809283e28b96d2d38537',
        name: 'Katarina Smith'
      },
      comments: [
        {
          author: {
            avatar: '/static/images/avatars/avatar_11.png',
            id: '5e8877da9a65442b11551975',
            name: 'Shen Zhi'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fde4992eca63b9e9ef5',
          message:
            'That’s actually deep. Thanks for the design, would you consider making an interaction?'
        },
        {
          author: {
            avatar: '/static/images/avatars/avatar_4.png',
            id: '5e887b209c28ac3dd97f6db5',
            name: 'Alex Richardson'
          },
          createdAt: moment()
            .subtract(2, 'hours')
            .toDate()
            .getTime(),
          id: '5e887feb11b7add1ebfcca78',
          message: 'Oh... so sentimental'
        }
      ],
      createdAt: moment()
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      id: '5e887faf03e78a5359765636',
      liked: false,
      likes: 65,
      message:
        'As a human being, you are designed in a way that makes you incapable of experiencing any positive emotion unless you set an aim and progress towards it. What makes you happy is not, in fact, attaining it, but making progress towards it.'
    }
  ]
});

mock.onGet('/api/social/users/1/posts').reply(200, {
  posts: [
    {
      author: {
        avatar: '/static/images/avatars/avatar_6.png',
        id: '5e86809283e28b96d2d38537',
        name: 'Katarina Smith'
      },
      comments: [
        {
          author: {
            avatar: '/static/images/avatars/avatar_5.png',
            id: '5e887b7602bdbc4dbb234b27',
            name: 'Anje Keizer'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fc17162ba254da30771',
          message: 'Could use some more statistics, but that’s me haha'
        },
        {
          author: {
            avatar: '/static/images/avatars/avatar_8.png',
            id: '5e887a1fbefd7938eea9c981',
            name: 'Miller Edwards'
          },
          createdAt: moment()
            .subtract(2, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fc759bebe8d5d54a2e5',
          message:
            'Hmm, honestly this looks nice but I would change the shadow though'
        }
      ],
      createdAt: moment()
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      id: '5e887faca2b7a1ddce01221a',
      liked: true,
      likes: 24,
      media: '/static/images/posts/post_1.png',
      message: 'Just made this home screen for a project, what-cha thinkin?'
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_6.png',
        id: '5e86809283e28b96d2d38537',
        name: 'Katarina Smith'
      },
      comments: [
        {
          author: {
            avatar: '/static/images/avatars/avatar_11.png',
            id: '5e8877da9a65442b11551975',
            name: 'Shen Zhi'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          id: '5e887fde4992eca63b9e9ef5',
          message:
            'That’s actually deep. Thanks for the design, would you consider making an interaction?'
        },
        {
          author: {
            avatar: '/static/images/avatars/avatar_4.png',
            id: '5e887b209c28ac3dd97f6db5',
            name: 'Alex Richardson'
          },
          createdAt: moment()
            .subtract(2, 'hours')
            .toDate()
            .getTime(),
          id: '5e887feb11b7add1ebfcca78',
          message: 'Oh... so sentimental'
        }
      ],
      createdAt: moment()
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      id: '5e887faf03e78a5359765636',
      liked: false,
      likes: 65,
      message:
        'As a human being, you are designed in a way that makes you incapable of experiencing any positive emotion unless you set an aim and progress towards it. What makes you happy is not, in fact, attaining it, but making progress towards it.'
    }
  ]
});

mock.onGet('/api/social/users/1/projects').reply(200, {
  projects: [
    {
      author: {
        avatar: '/static/images/avatars/avatar_5.png',
        id: '5e887b7602bdbc4dbb234b27',
        name: 'Anje Keizer'
      },
      id: '5e88832046990a87d32e6caf',
      liked: true,
      likes: 57,
      location: 'Europe',
      media: '/static/images/projects/project_2.png',
      rating: 3,
      subscribers: 5,
      technology: 'Angular JS',
      title: 'Mella Full Screen Slider',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(24, 'minutes')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_6.png',
        id: '5e86809283e28b96d2d38537',
        name: 'Katarina Smith'
      },
      id: '5e88832b7e1ddc356c94dcc2',
      liked: false,
      likes: 91,
      location: 'Europe',
      media: '/static/images/projects/project_1.png',
      rating: 4.3,
      subscribers: 10,
      technology: 'React JS',
      title: 'Dashboard Design',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(1, 'hour')
        .toDate()
        .getTime()
    },
    {
      author: {
        avatar: '/static/images/avatars/avatar_7.png',
        id: '5e86805e2bafd54f66cc95c3',
        name: 'Adam Denisov'
      },
      id: '5e888331f4107aa4013caada',
      liked: true,
      likes: 6,
      location: 'Europe',
      media: '',
      rating: 5,
      subscribers: 4,
      technology: 'Vue JS',
      title: 'Ten80 Web Design',
      type: 'Full-Time',
      updatedAt: moment()
        .subtract(16, 'hour')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/social/users/1/reviews').reply(200, {
  reviews: [
    {
      createdAt: moment()
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      currency: '$',
      hours: 31,
      id: '5e887f7404ed12e9afb71841',
      message:
        'Shen was really great during the all time session we created the project',
      pricePerHour: '43.00',
      project: {
        price: '5,240.00',
        title: 'Mella Full Screen Slider'
      },
      rating: 4,
      reviewer: {
        avatar: '/static/images/avatars/avatar_2.png',
        id: '5e887a62195cc5aef7e8ca5d',
        name: 'Ekaterina Tankova'
      }
    },
    {
      createdAt: moment()
        .subtract(8, 'days')
        .toDate()
        .getTime(),
      currency: '$',
      hours: 76,
      id: '5e887f7b91b9b5330c49a318',
      message:
        "Being the savage's bowsman, that is, the person who pulled the bow-oar in his boat (the second one from forward), it was my cheerful duty to attend upon him while taking that hard-scrabble scramble upon the dead whale's back. You have seen Italian organ-boys holding a dancing-ape by a long cord. Just so, from the ship's steep side, did I hold Queequeg down there in the sea, by what is technically called in the fishery a monkey-rope, attached to a strong strip of canvas belted round his waist.",
      pricePerHour: '38.00',
      project: {
        price: '3,680.00',
        title: 'Dashboard Design'
      },
      rating: 5,
      reviewer: {
        avatar: '/static/images/avatars/avatar_3.png',
        id: '5e887ac47eed253091be10cb',
        name: 'Cao Yu'
      }
    }
  ]
});

mock.onGet('/api/social/users/1/connections').reply(200, {
  connections: [
    {
      avatar: '/static/images/avatars/avatar_2.png',
      common: 12,
      id: '5e887a62195cc5aef7e8ca5d',
      name: 'Ekaterina Tankova',
      status: 'connected'
    },
    {
      avatar: '/static/images/avatars/avatar_3.png',
      common: 10,
      id: '5e887ac47eed253091be10cb',
      name: 'Cao Yu',
      status: 'rejected'
    },
    {
      avatar: '/static/images/avatars/avatar_4.png',
      common: 8,
      id: '5e887b209c28ac3dd97f6db5',
      name: 'Alex Richardson',
      status: 'pending'
    },
    {
      avatar: '/static/images/avatars/avatar_7.png',
      common: 5,
      id: '5e86805e2bafd54f66cc95c3',
      name: 'Adam Denisov',
      status: 'not_connected'
    },
    {
      avatar: '/static/images/avatars/avatar_8.png',
      common: 1,
      id: '5e887a1fbefd7938eea9c981',
      name: 'Miller Edwards',
      status: 'connected'
    }
  ]
});
