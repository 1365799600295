import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {}
}));

export default function Code({ className, ...rest }) {
  const classes = useStyles();

  return <code className={clsx(classes.root, className)} {...rest} />;
}

Code.propTypes = {
  className: PropTypes.string.isRequired
};
