/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Clock, PieChart as PieChartIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { isATPI, isAster, isEcosystem } from 'src/utils/settings';
import { matchPath, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Apps2LineIcon from 'remixicon-react/Apps2LineIcon';
import CommunityLineIcon from 'remixicon-react/CommunityLineIcon';
import Focus2LineIcon from 'remixicon-react/Focus2LineIcon';
import Group2FillIcon from 'remixicon-react/Group2FillIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';
import HandCoinFillIcon from 'remixicon-react/HandCoinFillIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import HandHeartLineIcon from 'remixicon-react/HandHeartLineIcon';
import HistoryLineIcon from 'remixicon-react/HistoryLineIcon';
import Logo from 'src/components/Logo';
import MembersIcon from './icons/MembersIcon';
import Message2LineIcon from 'remixicon-react/Message2LineIcon';
import NavItem from './NavItem';
import OrganizationsIcon from './icons/OrganizationsIcon';
import PenNibLineIcon from 'remixicon-react/PenNibLineIcon';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ProjectsIcon from './icons/ProjectsIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ServiceLineIcon from 'remixicon-react/ServiceLineIcon';
import Settings3LineIcon from 'remixicon-react/Settings3LineIcon';
import ToolsLineIcon from 'remixicon-react/ToolsLineIcon';
import useConfiguration from 'src/hooks/useConfiguration';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      exact: false,
      path: item.href
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.items,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: 'pointer',
    height: 64,
    width: 64
  },
  desktopDrawer: {
    height: 'calc(100% - 64px)',
    top: 64,
    width: 256
  },
  mobileDrawer: {
    width: 256
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const {
    accountName,
    hideExtraAdminPages,
    hideBlogPages,
    ConfigurationHideOrganisationsDirectory,
    directorateAndTeamNames
  } = useConfiguration();

  const analyticsUserNavConfig = [
    {
      items: [
        {
          href: '/app/reports/overview',
          icon: PieChartIcon,
          title: 'Overview'
        },
        {
          href: '/app/reports/opportunities',
          icon: HandCoinLineIcon,
          title: 'Opportunities'
        },
        {
          href: '/app/reports/colleagues',
          icon: PenNibLineIcon,
          title: 'Colleagues'
        },
        {
          href: '/app/reports/community-partners',
          icon: Apps2LineIcon,
          title: 'Community Partners'
        },
        {
          href: '/app/reports/contractors',
          icon: ServiceLineIcon,
          title: 'Contractors & Suppliers'
        },
        {
          href: '/app/reports/donors',
          icon: ServiceLineIcon,
          title: 'Donors'
        },
        {
          href: '/app/reports/customers',
          icon: Focus2LineIcon,
          title: 'Customers'
        }
      ],
      subheader: 'Reports'
    }
  ];
  const limitedNavConfig = [
    {
      items: [
        {
          href: '/app/reports/overview',
          icon: PieChartIcon,
          title: 'Overview'
        }
      ],
      subheader: 'Reports'
    },
    {
      items: [
        {
          href: '/app/management/members',
          icon: MembersIcon,
          title: 'Members'
        },
        ...(!ConfigurationHideOrganisationsDirectory
          ? [
              {
                href: '/app/management/groups',
                icon: OrganizationsIcon,
                title: 'Organizations'
              }
            ]
          : []),
        ...(!ConfigurationHideOrganisationsDirectory
          ? [
              {
                href: '/app/management/groups-new',
                icon: Group2FillIcon,
                title: 'Groups'
              }
            ]
          : []),
        ...(!ConfigurationHideOrganisationsDirectory
          ? [
              {
                href: '/app/management/projects',
                icon: ProjectsIcon,
                title: 'Projects'
              }
            ]
          : []),
        {
          href: '/app/management/challenges',
          icon: Focus2LineIcon,
          title: 'Opportunities'
        },
        {
          href: '/app/management/supports',
          icon: HandHeartLineIcon,
          title: 'Support Offers'
        },
        {
          href: '/app/management/manual-volunteers',
          icon: 'https://res.cloudinary.com/goodsted/image/upload/v1634048163/Assets/TimeLine.svg',
          title: 'Logged hours'
        }
      ],
      subheader: 'Management'
    }
  ];
  const navConfig = [
    {
      items: [
        {
          href: '/app/reports/overview',
          icon: PieChartIcon,
          title: 'Overview'
        },
        ...(isEcosystem(accountName)
          ? [
              {
                href: '/app/reports/colleagues',
                icon: PenNibLineIcon,
                title: 'Colleagues'
              }
            ]
          : []),
        ...(isAster(accountName)
          ? [
              {
                href: '/app/reports/opportunities',
                icon: HandCoinLineIcon,
                title: 'Opportunities'
              },
              {
                href: '/app/reports/community-partners',
                icon: Apps2LineIcon,
                title: 'Community Partners'
              },
              {
                href: '/app/reports/contractors',
                icon: ServiceLineIcon,
                title: 'Contractors & Suppliers'
              },
              {
                href: '/app/reports/donors',
                icon: ServiceLineIcon,
                title: 'Donors'
              },
              {
                href: '/app/reports/customers',
                icon: Focus2LineIcon,
                title: 'Customers'
              }
            ]
          : [])
        // {
        //   href: '/app/reports/challenges',
        //   icon: BarChartIcon,
        //   title: 'Opportunities'
        // },
        // {
        //   href: '/app/reports/volunteers',
        //   icon: BarChartIcon,
        //   title: 'Volunteers'
        // },
        // {
        //   href: '/app/reports/beneficiaries',
        //   icon: BarChartIcon,
        //   title: 'Beneficiaries'
        // }
      ],
      subheader: 'Reports'
    },
    {
      items: [
        ...(!hideBlogPages
          ? [
              {
                href: '/app/management/blogs',
                icon: Focus2LineIcon,
                title: 'Blogs'
              }
            ]
          : []),
        {
          href: '/app/management/members',
          icon: MembersIcon,
          title: 'Members'
        },
        ...(!ConfigurationHideOrganisationsDirectory
          ? [
              {
                href: '/app/management/groups',
                icon: OrganizationsIcon,
                title: 'Organizations'
              }
            ]
          : []),
        ...(!ConfigurationHideOrganisationsDirectory
          ? [
              {
                href: '/app/management/groups-new',
                icon: Group2FillIcon,
                title: 'Groups'
              }
            ]
          : []),
        ...(!ConfigurationHideOrganisationsDirectory
          ? [
              {
                href: '/app/management/projects',
                icon: ProjectsIcon,
                title: 'Projects'
              }
            ]
          : []),
        ...(isEcosystem(accountName)
          ? [
              {
                href: '/app/management/directorates',
                icon: CommunityLineIcon,
                title: directorateAndTeamNames.directorateNamePlural
              }
            ]
          : []),
        {
          href: '/app/management/challenges',
          icon: Focus2LineIcon,
          title: 'Opportunities'
        },
        {
          href: '/app/management/messages',
          icon: Message2LineIcon,
          title: 'Messages'
        },
        {
          href: '/app/management/supports',
          icon: HandHeartLineIcon,
          title: 'Support Offers'
        },
        {
          href: '/app/management/manual-volunteers',
          icon: Clock,
          title: 'Logged hours'
        },
        ...(isEcosystem(accountName)
          ? [
              {
                href: '/app/management/donations',
                icon: HandCoinLineIcon,
                title: 'Donation / Grant'
              },
              {
                href: '/app/management/donationTypes',
                icon: HandCoinFillIcon,
                title: 'Donation Types'
              }
            ]
          : []),
        {
          href: '/app/management/skills',
          icon: ToolsLineIcon,
          title: 'Skills'
        },
        {
          href: '/app/management/updates',
          icon: HistoryLineIcon,
          title: 'Updates'
        },
        {
          href: '/app/management/causes',
          icon: HandHeartLineIcon,
          title: 'Causes'
        },
        {
          href: '/app/management/pricing-requests',
          icon: HandHeartLineIcon,
          title: 'Price Requests'
        },
        {
          href: '/app/management/configurations',
          icon: Settings3LineIcon,
          title: 'Configurations'
        }
      ],
      subheader: 'Management'
    }
  ];

  function getNavConfig(user) {
    if (user.role === 'AnalyticsUser') return analyticsUserNavConfig;
    if (hideExtraAdminPages) return limitedNavConfig;
    return navConfig;
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="#">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.profileImage}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="#"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {getNavConfig(user).map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
