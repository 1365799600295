import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  createStyles,
  jssPreset,
  makeStyles
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { create } from 'jss';
import { createBrowserHistory } from 'history';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import MomentUtils from '@date-io/moment';
import React from 'react';
import rtl from 'jss-rtl';
// import SettingsNotification from 'src/components/SettingsNotification';
import { useTheme } from 'src/theme';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import Routes from 'src/Routes';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';

const history = createBrowserHistory({
  basename: `${process.env.PUBLIC_URL}/`
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '#root': {
        height: '100%',
        width: '100%'
      },
      '*': {
        boxSizing: 'border-box',
        margin: 0
      },
      body: {
        height: '100%',
        width: '100%'
      },
      html: {
        '-moz-osx-font-smoothing': 'grayscale',
        '-webkit-font-smoothing': 'antialiased',
        height: '100%',
        width: '100%'
      }
    }
  })
);
// Disable some library warnings
const error = console.error;
function logError(...parameters) {
  const filter = parameters.find((p) => {
    const parameter = p.includes ? p : p.toString();
    return (
      parameter.includes('Warning: %s is deprecated in StrictMode') ||
      parameter.includes('Warning:') ||
      parameter.includes('Material-UI')
    );
  });
  if (!filter) error(...parameters);
}
console.error = logError;

const warn = console.warn;
function logWarning(...parameters) {
  const filter = parameters.find((p) => {
    const parameter = p.includes ? p : p.toString();
    return (
      parameter.includes('UNSAFE_') ||
      parameter.includes('SourceMap') ||
      parameter.includes('DevTools') ||
      parameter.includes('Material-UI') ||
      parameter.includes('The theme undefined is not valid')
    );
  });
  if (!filter) warn(...parameters);
}
console.warn = logWarning;

function App() {
  useStyles();

  const { settings } = useSettings();
  const theme = useTheme(settings);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history} basename={`${process.env.PUBLIC_URL}/`}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                {/* <SettingsNotification/> */}
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
