import { get } from 'lodash';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import React from 'react';

const GET_CONFIG = gql`
  query Configuration {
    websiteLogo: Configuration(name: "websiteLogo") {
      value
    }
    websiteLogoInverted: Configuration(name: "websiteLogoInverted") {
      value
    }
    favicon: Configuration(name: "favicon") {
      value
    }
  }
`;

function Logo({ inverted = true, ...props }) {
  const Configuration = useQuery(GET_CONFIG);
  const favicon = document.getElementById('favicon');
  const newFavicon = get(Configuration, 'data.favicon.value');
  if (newFavicon) {
    favicon.href = newFavicon;
  }
  const src = inverted
    ? get(
        Configuration,
        'data.websiteLogoInverted.value',
        get(
          Configuration,
          'data.websiteLogo.value',
          '/static/goodsted-logo.png'
        )
      )
    : get(
        Configuration,
        'data.websiteLogo.value',
        get(
          Configuration,
          'data.websiteLogoInverted.value',
          '/static/goodsted-logo.png'
        )
      );
  return <img alt="Logo" src={src} {...props} />;
}

export default Logo;
