import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '& > p': {
      color: theme.palette.text.secondary,
      marginBottom: 0
    },
    borderLeft: `4px solid ${theme.palette.text.secondary}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

function Blockquote(props) {
  const classes = useStyles();

  return <blockquote className={classes.root} {...props} />;
}

export default Blockquote;
