import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import DebounceLink from 'apollo-link-debounce';
import authService from 'src/services/authService';

const uri =
  process.env.REACT_APP_API_URL ||
  'https://graphcool-staging.goodsted.com/simple/v1/ckbc9i6ru00040106zqifwy16';
// const uri = 'https://graphcool-staging.goodsted.com/simple/v1/ckbc9i6ru00040106zqifwy16';
const cache = new InMemoryCache();
const uriFunction = ({ operationName }) => {
  return `${uri}?${operationName}`;
};

const httpLink = new HttpLink({ uri: uriFunction });
const DEFAULT_DEBOUNCE_TIMEOUT = 300;

const debounceAuthLink = ApolloLink.from([
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  (operation, forward) => {
    // add the authorization to the headers
    const token = localStorage.getItem('accessToken');

    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      }
    });

    return forward(operation);
  },
  onError(({ graphQLErrors, networkError }) => {
    if (
      graphQLErrors &&
      graphQLErrors.some(
        (e) =>
          e.message === 'Insufficient Permissions' ||
          e.message === 'Not Authorised!'
      )
    ) {
      // eslint-disable-next-line no-console
      console.error('Insufficient Permissions');
      authService.logout();
      // eslint-disable-next-line no-console
      // localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN);
      // localStorage.removeItem('auth-state');
      window.location = '/login';
    }
    if (networkError) {
      console.error('networkError', networkError);
    }
  }),
  httpLink
]);
export const client = new ApolloClient({
  cache,
  link: debounceAuthLink
});
